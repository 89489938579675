<template>
    <div class="interest-areas">
        <portal to="atlas-sidebar-title">
            <span>{{ $t('ATLAS.INTEREST_AREAS_MANAGER.TITLE') }}</span>
        </portal>
        <div class="layer-container">
        </div>
    </div>
</template>
<script>

export default {
    name: 'InterestAreasManager',
    computed: {},
    methods: {},
    created() {}
}
</script>
<style lang="scss" scoped>
    .layer-container {
        padding: 0;
        overflow: auto;
    }
</style>