import { render, staticRenderFns } from "./InterestAreasManager.vue?vue&type=template&id=8703f042&scoped=true&"
import script from "./InterestAreasManager.vue?vue&type=script&lang=js&"
export * from "./InterestAreasManager.vue?vue&type=script&lang=js&"
import style0 from "./InterestAreasManager.vue?vue&type=style&index=0&id=8703f042&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8703f042",
  null
  
)

export default component.exports